
import FormInput from '@/components/FormInput/FormInput.vue'
import RichEditor from '@/components/RichEditor/RichEditor.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Widget from '@/components/Widget/Widget.vue'
import FaqModel from '@/models/FaqModel'
import ViewModel from '@/models/ViewModel'
import { Component, Vue } from 'vue-property-decorator'
import FooterNav from '@/components/FooterNav/FooterNav.vue'

@Component({
  components: {
    RichEditor,
    Widget,
    FaqModel,
    FormInput,
    SelectPicker,
    FooterNav,
  },
})
export default class FaqEdit extends ViewModel {
  public FAQ: FaqModel = new FaqModel()

  public ready = false

  public loading: boolean = false

  public custom_targetting: any = []

  public custom_tag_list: any = []

  public modules = FaqModel.module_list

  public action_list = [
    {
      name: 'View',
      value: 'view',
    },
    {
      name: 'Edit',
      value: 'edit',
    },
    {
      name: 'Delete',
      value: 'delete',
    },
  ]

  public get title() {
    if (this.$route.params.id) return 'Faq Edit'
    return 'New Question'
  }

  public load() {
    this.loading = true
    FaqModel.find(this.$route.params.id).then(res => {
      this.FAQ = res
      this.loadTags()
      this.loading = false
    })
  }

  public mounted() {
    this.ready = true

    if (!this.$route.params.id) {
      this.FAQ = new FaqModel()
      this.loading = false
    } else {
      this.load()
    }
  }

  public loadTags() {
    this.custom_tag_list = this.FAQ.tags.map(t => ({
      name: t,
      value: t,
    }))
  }

  public addTag(newTag: string) {
    const tag = {
      name: newTag,
      value: newTag,
    }
    if (!this.FAQ.tags) this.FAQ.tags = []
    this.FAQ.tags.push(newTag)
    this.custom_tag_list.push(tag)
  }

  public clearAll() {
    this.custom_targetting = []
  }

  public saveFaq() {
    this.loading = true
    this.FAQ.save().then(res => {
      if ([200, 201].includes(res.status)) {
        this.$router.push({ name: 'faq-home' })
      }

      this.loading = false
    })
  }
}
